.carousel .control-dots {
    margin: 0;
}

.carousel .control-dots .dot {
    background: #0A6FB8
}

.carousel.carousel-slider .control-arrow {
    background: rgba(0, 0, 0, 0.15);
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0.3);
}

.carousel-root {
    height: 100%;
}

.carousel-slider {
    height: 100%;
}

.slider-wrapper {
    height: 100%;
}

.slider {
    height: 100%;
}